html {
    color: rgba(110, 110, 110, 1);
}
a {
    text-decoration: none;
}
strong {
    font-weight: bold;
}
.h5{
    font-family: 'NanumSquare Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin : 10px; 
    /* line-height: 140%; */

/* 
    text-align: center; */

    color: #000000;
}
.h4{

    font-family: 'NanumSquare Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;

/* 
    text-align: center; */
    text-align: left;
    margin: 15px;
    color: #000000;
}
.h3{
    width: 134px;
    height: 42px;
    left: calc(50% - 134px/2);
    top: 147px;
    font-family: 'NanumSquare Neo';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 140%;
    text-align: center;

    color: #000000;
}

.header {
    display: flex;
    text-align: center;

}
/* .header a {
    margin: 0 auto;
    font-size: 3rem;
    align-items: center;
    color: #ed6174;
} */
.wrapper {
    position: relative;
    width: 1440px;
    height: 1369px;
    background: #FFFFFF;
    /* text-align: center; */
}

.container {
    box-sizing: border-box;
    position: absolute;
    width: 375px;
    height: 1369px;
    left: 532px;
    top: 0px;
    background: #FFFFFF;
    text-align: center;
    margin-top : 30px;
}

.main__img {
    /* position: absolute; */
    width: 360px;
    height: 400px;
    left: 567px;
    top: 231px;

}

.result__city {
    margin: 1rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    color: #4e4e4e;
}

.result__type {
    font-family: 'NanumSquare Neo';
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    text-align: center;
    color: #4e4e4e;
    line-height: 140%;
    white-space: pre-wrap;
}
.reust__title h2 {
    width: 46px;
    height: 22px;
    left: calc(50% - 46px/2 - 116px);
    top: 712px;

    /* ButtonText/BT1 - 16B */

    font-family: 'NanumSquare Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    color: #000000;
    margin: 10px 10px;
}
.reust__title1 h2 {
    width: 61px;
    height: 22px;
    left: calc(50% - 61px/2 - 108.5px);
    top: 788px;

    /* ButtonText/BT1 - 16B */

    font-family: 'NanumSquare Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */


    color: #000000;
    margin: 10px 10px;
}
.result__style__wrapper {
    list-style: disc;
    list-style-position: inside;
    text-align: left;
    line-height: 140%;

}
.result__style__detail {
    padding: 0.5rem;
}

.result__advice__box {
    margin-top: 4rem;
}
.result__advice {
    display: flex;
    margin: 1.2rem 0 1.2rem 0;
}

.result__advice img {
    margin-right: 12px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.result__advice h4 {
    font-weight: bold;
    color: #4e4e4e;
}
.result__advice p {
    margin: 1rem 0 1rem 0;
    font-size: 14px;
}

.button__box {
    margin: 0 auto;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.button__box button {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	gap: 10px;
	position: absolute;
	width: 308px;
	height: 30px;
	
	top: 1300px;
	background: #8CC2E9;
	border-radius: 30px;
	font-family: 'NanumSquare Neo';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #FFFFFF;
	flex: none;
	order: 0;
	flex-grow: 0;
}
.button__box a {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	gap: 10px;
	position: absolute;
	width: 308px;
	height: 30px;
	left: calc(50% - 345px/2);
	top: 1100px;
	background: #8CC2E9;
	border-radius: 30px;
	font-family: 'NanumSquare Neo';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #FFFFFF;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.copy__button {
    display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	gap: 10px;
	position: absolute;
	width: 308px;
	height: 30px;
	left: calc(50% - 345px/2);
	top: 1200px;
	background: #8CC2E9;
	border-radius: 30px;
	font-family: 'NanumSquare Neo';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #FFFFFF;
	flex: none;
	order: 0;
	flex-grow: 0;
}

.button:active {
    color: #fff;
	background: #429DDF;
	transform: scale(0.95);
	transition: 10ms;
	border: none;
}
.icon {
    font-size: 1rem;
    margin-left: 6px;
}

.shareBox {
    margin: 1rem 0 1rem 0;
    display: flex;
    justify-content: center;
}

.hashtag {
    display: flex;
    list-style: none;
    padding: 0;
    color: #429DDF;
    margin: 10px;
  }
  
  /* .hashtag li {
    flex: 1;
  } */
  .description_box{
    box-sizing: border-box;
    /* position: absolute; */
    width: 355px;
    height: 360px;
    left: 570px;
    top: 692px;

    /* Primary/Blue */

    border: 2px solid #8CC2E9;
    border-radius: 10px;
   

  }
  

