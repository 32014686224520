.middle h2 {
	position: absolute;
	width: 295px;
	height: 42px;
	left: calc(50% - 295px/2 + 0.5px);
	top: 227px;
	font-family: 'NanumSquare Neo';
	font-style: normal;
	font-weight: 800;
	font-size: 30px;
	line-height: 42px;
	text-align: center;
	color: #000000;
}
.middle p {
	position: absolute;
width: 224px;
height: 36px;
left: 612px;
top: 285px;
font-family: 'NanumSquare Neo';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 18px;
text-align: center;

color: #000000;

}
.logo {
	width: 150px;
	height: 166px;
	Top: 5px;
	Left: 645px;
}

.img {
	margin-top: 100px;
	width: 247px;
	height: 301px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	width: 375px;
	height: 800px;
	background: #FFFFFF;

}

.start__button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 10px;
	gap: 10px;
	position: absolute;
	width: 308px;
	height: 30px;
	left: calc(50% - 320px/2);
	top: 666px;
	background: #8CC2E9;
	border-radius: 30px;
	font-family: 'NanumSquare Neo';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 22px;
	color: #FFFFFF;
	flex: none;
	order: 0;
	flex-grow: 0;
}



.start__button:active {
	color: #fff;
	background: #429DDF;
	transform: scale(0.95);
	transition: 10ms;
	border: none;
}

.top {
	margin: 5vh 0 5vh;
}


.icon {
	margin-left: 6px;
}

.copy__button {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
}

.header {
	font-weight: bolder;
}

.wrapper{
	position: relative;
	width: 1440px;
	height: 800px;
	background: #FFFFFF;
}
