body {
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

button {
    border: none;
}

.slider {
    width: 1200vw;
}
.content {
    margin-top: 15vh;
    width: 100vw;
    float: left;
}
.wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.logo {
    width: 315px;
    height: 261px;
    margin-top: -100px;
    margin-left: 39%;
}

.back_btn {
    width: 30px;
    height: 30px;
}

.top {
    height: 20vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.mbti__counter {
    font-size: 2rem;
    font-weight: bold;
    margin-top: -100px;
}

.mbti__progress__color {
    color: #8cc2e9;
    font-size: 1.5em;
}

.mbti__end__color {
    color: #e8e7e6;
}

.mbti__question {
    color: #4e4e4e;
    font-size: 1.4rem;
    font-weight: bolder;
    text-align: center;
    margin-top: 30px;
    line-height: 140%;
    white-space: pre-wrap;
}

.mbti__btn__box {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 25vh;
    margin: 0 auto;
}

.mbti__button {
    display: flex;
    justify-content: center;
    height: 45%;
    margin: 1rem;
    padding: 1.2rem;
    box-sizing: border-box;
    background-color: #fff;
    color: rgba(110, 110, 110, 1);
    border: 2px solid #8cc2e9;
    border-radius: 30px;
    border-color: #8cc2e9;
    font-weight: 600;
}
.goback_button {
    background-color: transparent;
    margin-top: 40px;
    margin-left: 40px;
    color: #4e4e4e;
    font-size: 1.7rem;
    font-weight: bolder;
    text-align: center;
}

.mbti__button:active {
    color: #fff;
    background-color: #8cc2e9;
    transform: scale(0.95);
    transition: 100ms;
    border: none;
}

.loading__container {
    display: flex;
    width: 50%;
    background-color: white;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    margin-top: 40vh;
}

.loading__container h2 {
    padding: 2rem;
}
.loading {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid #8cc2e9;
    border-top: 1px solid white;
    animation: spin 2s linear infinite;
}

.ticket_font {
    position: absolute;
    top: 12%;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 1rem;
    color: #8cc2e9;
}

.ticket_div{
    position: absolute;
    top: 20%;
    width: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ticket {
    width: 10vw;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@media screen and (min-width: 768px) {
    html {
        background-color: white; /* 데스크탑 화면의 배경색을 지정하세요 */

        /* background-size: cover; */ /* 데스크탑 화면에서 배경을 커버하도록 설정할 수도 있습니다 */
    }

    .top {
        width: 400px;
        margin: 0 auto;
    }

    .mbti__btn__box {
        width: 25%;
    }

    .mbti__counter {
        text-align: center;
    }

    .mbti__button {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mbti__button:hover {
        color: #fff;
        background-color: #8cc2e9;
        transform: scale(0.98);
        transition: 100ms;
    }

    .loading {
        position: absolute;
        bottom: 4%;
        width: 10rem;
        height: 10rem;
    }

    .ticket {
        width: 30rem;
    }
}
